<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      search_route="/brands/add"
      :search_title="$t('titles.addNew')"
      icon="fa-plus"
    />
    <!-- End Breadcrumb -->
    <main-filter @getResult="setRows" categories withCategory />
    <!-- Start Main Loader -->
    <!-- End Breadcrumb -->
    <MainLoader v-if="loading"></MainLoader>
    <!-- End Main Loader -->
    <div class="fadeIn" v-else>
      <!-- Start Main Section -->
      <main>
        <v-data-table
          class="elevation-1 thumb diff_table"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Select no data State -->

          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <img
              :src="item.image"
              width="60"
              height="60"
              class="rounded-circle"
              alt=""
              @click="show_model_1"
            />
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'success' : 'canceled'"
              >{{ item.is_active ? $t("active") : $t("inactive") }}</span
            >
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Start:: Users Routes -->

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon class="show" small @click="editItem(item)">
                fal fa-edit
              </v-icon>

              <v-icon
                class="delete"
                small
                v-if="item.id != 2"
                @click="deleteItem(item)"
              >
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <div class="d-flex justify-content-between">
              <h3 class="table-title title d-inline-flex">
                {{ $t("titles.brands") }} ({{ statisticsItem }})
              </h3>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <section
                class="table-buttons d-inline-flex p-0"
                style="width: fit-content"
              >
                <!-- Add -->

                <!-- Delete -->
                <button
                  type="button"
                  class="button_delete ml-5"
                  v-if="selected.length > 0"
                  @click="deleteSelected"
                >
                  <i class="far fa-trash-alt"></i>
                  &nbsp;
                  {{ $t("table.deleteSelected") }}
                </button>
                <div class="px-5 pt-3 frm-action d-flex flex-row gap-2">
                  <a
                    class="export btn"
                    href="https://shams-pharmacis.products.aait-d.com/api/dashboard/brand-export"
                    download="true"
                  >
                    <span> Download Sheet <i class="fal fa-download"></i></span>
                  </a>
                  <button class="export btn" @click="importModel = true">
                    <span> Import <i class="fal fa-upload"></i></span>
                  </button>
                  <button class="export btn">
                    <export-excel
                      :fetch="exportFields"
                      :meta="json_meta"
                      :name="$t(`titles.brands`)"
                      :worksheet="$t(`titles.brands`)"
                    >
                      Export <i class="fal fa-download"></i
                    ></export-excel>
                  </button>
                </div>
              </section>
            </div>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>

      <!-- End Statistics Card-->
      <!-- Start Pagination -->
      <template>
        <div
          class="pagination_container text-center mb-5 d-flex justify-content-end"
        >
          <!-- <div class="select-pagination d-flex">
            <span class="first">{{ $t('show') }}</span>
            <v-select
              :items="[5, 20, 50, 100]"
              v-model="paginations.items_per_page"
            ></v-select>
            <span>{{ $t('entries') }}</span>
          </div> -->

          <v-pagination
            color="primary"
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="5"
            @input="fetchData($event)"
          ></v-pagination>
        </div>
      </template>
      <!-- End Pagination -->
    </div>

    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <!-- End Image_Model -->
    <!-- Delete dialog -->
    <v-dialog v-model="importModel" width="500">
      <v-card>
        <!-- START:: FILE INPUT -->
        <v-card-text>
          <div class="input_wrapper top_label file_input">
            <span class="file_input_label"> تحميل الملف </span>
            <label
              for="file_input_1"
              class="form-label"
              v-if="!selectedFile.file"
            ></label>
            <label for="file_input_1" class="form-label" v-else>
              {{ selectedFile.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="file_input_1"
              @change="handelSelectedFile"
            />
          </div>

          <!-- END:: FILE INPUT -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#1B5E20"
            :loading="importLoading"
            @click="importFileConfirm"
          >
            {{ $t("table.deletedialog.ok") }}
          </v-btn>
          <v-btn color="#F44336" @click="importModel = false">
            {{ $t("table.deletedialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // ========== Main Loader
      loaderPage: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.brands.title"),
          disabled: false,
          href: "/brands/show-all",
        },
        {
          text: this.$t("breadcrumb.brands.all"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: null,

      // ========== Top Section
      search: "",
      // panel: [],

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
      },

      // ========== Loding
      loading: false,
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      importModel: false,
      importLoading: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            width: "80",
            sortable: false,
          },
          {
            text: "الصورة",
            align: "center",
            value: "image",
            sortable: true,
          },
          {
            text: "الاسم",
            align: "center",
            value: "name",
            sortable: true,
          },

          {
            text: "ترتيب العرض",
            align: "center",
            value: "ordering",
            sortable: true,
          },

          {
            text: "الحالة",
            align: "center",
            value: "is_active",
            sortable: true,
          },

          {
            text: "الاجراءات",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            width: "80",
            sortable: false,
          },
          {
            text: "Image",
            align: "center",
            value: "image",
            sortable: true,
          },
          {
            text: "Name",
            align: "center",
            value: "name",
            sortable: true,
          },

          {
            text: "Order",
            align: "center",
            value: "ordering",
            sortable: true,
          },

          {
            text: "Status",
            align: "center",
            value: "is_active",
            sortable: true,
          },

          {
            text: "Control",
            align: "center",
            value: "actions",
            sortable: true,
          },
        ];
      }
    },
  },

  methods: {
    async exportFields() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_brand",
      });
      let newData = [];
      data.data.data.forEach((el) => {
        let newObject = {};
        newObject[`${this.$t("forms.labels.image")}`] = el.image;
        newObject[`${this.$t("forms.labels.name")}`] = el.name;
        newObject[`${this.$t("forms.labels.ordering")}`] = el.ordering;
        newObject[`${this.$t("forms.labels.status")}`] = el.is_active
          ? this.$t("active")
          : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // exportProducts() {
    //   this.$axios.get("products-export");
    // },

    importFileConfirm() {
      if (!this.selectedFile.file) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي اختيار الملف اولا ",
          position: "bottomRight",
        });
        return;
      } else {
        this.importLoading = true;
        const frmData = new FormData();
        frmData.append("brands_file", this.selectedFile.file);
        this.$axios
          .post("import-file-brand", frmData)
          .then(() => {
            this.importModel = false;
            this.importLoading = false;
            this.$iziToast.success({
              timeout: 2000,
              message: "تم التحميل بنجاح",
              position: "bottomRight",
            });
            this.selectedFile = {
              file: null,
              name: null,
              path: null,
            };
            this.setRows();
          })
          .catch((err) => {
            // this.importModel = false;
            this.importLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/brands/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/brands/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/brands/edit/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `brands/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "POST",
        url: `brands/deleteAll`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows(e) {
      this.loading = true;
      const params = new URLSearchParams();
      params.append("keyword", e?.searchInput ? e.searchInput : "");
      params.append("page", this.paginations.current_page);
      params.append(
        "category_id",
        e?.selectedCategory ? e.selectedCategory.id : ""
      );

      this.$axios({
        method: "GET",
        url: "brands",
        params: params,
      })
        .then((res) => {
          this.rows = res.data.data;
          this.statisticsItem = res.data.meta?.total;
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },

  // ======= hooks
};
</script>

<style lang="scss">
.v-expansion-panel::before {
  box-shadow: none;
  background: transparent;
}
</style>
